import Request from './request'
import addKeyLocalStorage from 'helper/localStorage'

export default class UserVihcleService {
  static async getListVihcle(data) {
    if (data.searchText == '') delete data.searchText
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          // kiểm tra : có phải lịch của user hãy không . nếu không bỏ qua nó
          const user = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
          data.data = data.data.filter((item) => item.appUserId === user.appUserId)

          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async userCheckVehicleInfo(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/fetchVehicleInfo',
        data
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
  static async getListVihcleImport(data , cancelEvent) {
    if (data.searchText == '') delete data.searchText
    return new Promise((resolve) => {
      Request.sendImportExport({
        method: 'POST',
        path: '/AppUserVehicle/user/getList',
        data ,
        cancelEvent
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          // kiểm tra : có phải lịch của user hãy không . nếu không bỏ qua nó
          const user = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
          data.data = data.data.filter((item) => item.appUserId === user.appUserId)

          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getListVihcleCustom({ data = {}, query = null, headers = {}, newUrl = null, customToken = '' }) {
    if (data.searchText == '') delete data.searchText
    return new Promise((resolve) => {
      Request.customSend({
        method: 'POST',
        path: '/AppUserVehicle/user/getList',
        data,
        query,
        headers,
        newUrl,
        customToken
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        console.log('statusCode', statusCode)
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async getDetailVihcle(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/getDetail',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async registVihcle(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/registerVehicle',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }
  static async updateVihcle(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/updateVehicle',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }
  static async updateVihcleExpiryDate(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicleSetting/user/userUpdateSettingVehicle',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async deleteVehicle(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/deleteVehicle',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async findByHash(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserVehicle/user/findByHash',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async uploadFileMedia(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Upload/uploadMediaFile',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async getAvailableSchedule(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/findAvailableSchedule',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async getInspectionFee(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/getInspectionFee',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getInsuranceFee(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/getInsuranceFee',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getRoadFee(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/getRoadFee',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
}
