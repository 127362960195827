import React, { useEffect, useRef, useState } from 'react'
import { Button, message, Modal, Radio, Rate, Space } from 'antd'
import './index.scss'
import useWindowDimensions from 'hooks/window-dimensions'
import BCHPlay from '../../assets/new-icons/BCHPlay.png'
import BApp from '../../assets/new-icons/BApp.png'
import { BANNER_TYPE } from 'constants/banner'
import { BANNER_LAYOUT } from 'constants/banner'
import { getBannerBySectionCache } from 'helper/getBannerBySectionCache'

export default function PopupOneDay() {
  const currentPath = window.location.pathname
  const isHomePage = currentPath === '/'
  const [open, setOpen] = useState(false)
  const { width } = useWindowDimensions()
  const [banners, setBanner] = useState([])

  const onClose = () => {
    setOpen(false)
  }

  const handleCheckOpenPopup = () => {
    // Số lần user vào web
    const openWeb = parseInt(localStorage.getItem('openWeb') || 0)
    // Cập nhật số lần user vào web
    localStorage.setItem('openWeb', openWeb + 1)
    // Get the last shown date from localStorage
    const lastShownDate = localStorage.getItem('lastShownDate')
    const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
    // Check if the popup has already been shown today
    if (lastShownDate === today) return // Exit if it has been shown today
    if (isHomePage) {
      setOpen(true)
      localStorage.setItem('lastShownDate', today) 
      localStorage.setItem('openWeb',0)// Update last shown date
    }
  }

  useEffect(() => {
    handleCheckOpenPopup()
    getBannerBySectionCache(BANNER_TYPE[BANNER_LAYOUT.DOWLOAD_APP]).then(data =>{
        setBanner(data)
    })
  }, [])

  const handleChPlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ttdk', '_blank')
  }

  const handleApp = () => {
    window.open('https://apps.apple.com/vn/app/ttdk-%C4%91%E1%BA%B7t-l%E1%BB%8Bch-%C4%91%C4%83ng-ki%E1%BB%83m/id1663487095?l=vi', '_blank')
  }

  const RenderButton = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div onClick={() => handleChPlay()} className="cursor">
          <img src={BCHPlay} width="170px" height="70px" style={{ marginTop: 2 }} />
        </div>
        <div className="style_app cursor" onClick={() => handleApp()}>
          <img src={BApp} width="155px" height="95px" />
        </div>
      </div>
    )
  }

  // check mobie
  if (width < 992) {
    return null
  }

  return (
    <Modal onCancel={onClose} placement="top" width={500} height={600} footer={null} open={open}>
      <div className={`review-headers sticky-header d-flex ai-c j-sb`} style={{ borderColor: 'transparent', height: '50px' }}>
        <div className="d-flex ai-c mg-auto">
          <div style={{ color: 'white', fontSize: '16px' }} className="title">
            TTDK - Đặt lịch đăng kiểm
          </div>
        </div>
      </div>
      <div className="container-reviews">
        { banners?.length > 0 ? <img src={banners[0]?.bannerImageUrl} width={452} height={300}/> : 
        <div style={{ marginTop: 10, textAlign: 'center' }}>
          <p>Vui lòng tải ứng dụng trên điện thoại di động và đặt lịch đăng kiểm để được tự động tra cứu phạt nguội mỗi ngày</p>
          <RenderButton />
        </div> }
      </div>
    </Modal>
  )
}
